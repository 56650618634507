import React, { useState, useEffect } from 'react';
import PropTypes from 'prop-types';
import * as d3 from 'd3';
import * as topojson from 'topojson-client';

import useResourceQuery from 'dpl/shared/fetching/hooks/useResourceQuery';
import LoadingWrapper from 'dpl/components/LoadingWrapper';

export default function TravelRadiusMap({
  id,
  origin,
  pinOrigin,
  radius,
  stateName,
  showPin,
  className,
  locationName
}) {
  const path = d3.geoPath();
  // the scale and translation numbers here are magic numbers
  const projection = d3.geoAlbersUsa().scale(1300).translate([487.5, 305]);

  const isOutsideMainland = /AK|Alaska|HI|Hawaii/.test(locationName);

  const { value: mainland } = useResourceQuery({
    url: '/states-albers-10m.json',
    enabled: true
  });

  const { value: everything } = useResourceQuery({
    url: '/all-albers-10m.json',
    enabled: true
  });

  const [us, setUS] = useState({ objects: { states: [], nation: [] } });
  const [highlightedStates, setHighlightedStates] = useState(null);

  useEffect(() => {
    let map;
    if (isOutsideMainland && everything) {
      map = everything;
    } else {
      map = mainland;
    }

    if (map.type === 'Topology') {
      setUS(map);
      if (stateName && stateName === 'all') {
        setHighlightedStates(map.objects.states);
      } else if (stateName) {
        setHighlightedStates({
          type: 'GeometryCollection',
          geometries: map.objects.states.geometries.filter(
            g => g.properties.name === stateName
          )
        });
      }
    }
  }, [stateName, locationName, mainland, everything]);

  // radius overrides for specific state views of unusual size or location
  if (stateName === 'Texas' || stateName === 'California') {
    radius = 200;
  } else if (stateName === 'Hawaii') {
    radius = 58;
  } else if (stateName === 'Alaska') {
    radius = 80;
  }

  const gradientId = `travelRadius-${id}`;
  const maskId = `travelRadiusMask-${id}`;

  const center = projection([origin[1], origin[0]]);

  const pin = pinOrigin ? projection([pinOrigin[1], pinOrigin[0]]) : center;

  if (!pin) {
    return null;
  }

  const viewMinX = center[0] - radius - 10;
  const viewMaxX = center[0] + radius + 10;
  const width = viewMaxX - viewMinX;
  const viewMinY = center[1] - radius - 10;
  const viewMaxY = center[1] + radius + 10;
  const height = viewMaxY - viewMinY;
  const viewBox = [viewMinX, viewMinY, width, height].join(' ');

  const pinScaleFactor = (width / 100) * 0.5;

  const pinTranslateX = pin[0] - 10 * pinScaleFactor;
  const pinTranslateY = pin[1] - 24 * pinScaleFactor;

  const pinTranslate = [pinTranslateX, pinTranslateY].join(',');
  const pinScale = [pinScaleFactor, pinScaleFactor].join(', ');

  return (
    <div className={className}>
      <LoadingWrapper isLoading={us.objects.states.length === 0}>
        <svg viewBox={viewBox}>
          <path
            fill="#E9E9ED"
            d={path(topojson.feature(us, us.objects.nation))}
          />
          {!highlightedStates && (
            <>
              <defs>
                <radialGradient id={gradientId}>
                  <stop offset="75%" stopColor="white" />
                  <stop offset="100%" stopColor="black" />
                </radialGradient>
                <mask id={maskId}>
                  <g transform={`translate(${center.join(',')})`}>
                    <circle r={radius} fill={`url('#${gradientId}')`} />
                  </g>
                </mask>
              </defs>
              <path
                fill="#BCD8FD"
                mask={`url('#${maskId}')`}
                d={path(topojson.feature(us, us.objects.nation))}
              />
            </>
          )}
          <path
            fill="none"
            stroke="#fff"
            strokeLinejoin="round"
            strokeLinecap="round"
            d={path(topojson.mesh(us, us.objects.states, (a, b) => a !== b))}
          />
          {highlightedStates && (
            <path
              fill="#BCD8FD"
              stroke="#fff"
              strokeLinejoin="round"
              strokeLinecap="round"
              d={path(topojson.feature(us, highlightedStates))}
            />
          )}
          {showPin && (
            <g
              transform={`translate(${pinTranslate}), scale(${pinScale})`}
              fill="black"
              stroke="white"
              strokeLinecap="round"
              strokeLinejoin="round"
              strokeWidth="1.5"
              clipRule="evenodd"
            >
              <path d="M12 21c-1.199 0-7.5-5.102-7.5-10.437C4.5 6.387 7.857 3 12 3c4.142 0 7.5 3.387 7.5 7.563C19.5 15.898 13.198 21 12 21Z" />
              <path d="M14.5 10.5a2.5 2.5 0 1 0-5 0 2.5 2.5 0 0 0 5 0Z" />
            </g>
          )}
        </svg>
      </LoadingWrapper>
    </div>
  );
}

TravelRadiusMap.propTypes = {
  id: PropTypes.string.isRequired,
  className: PropTypes.string,
  origin: PropTypes.arrayOf(PropTypes.number).isRequired,
  pinOrigin: PropTypes.arrayOf(PropTypes.number),
  radius: PropTypes.number,
  stateName: PropTypes.string,
  showPin: PropTypes.bool.isRequired,
  locationName: PropTypes.string.isRequired
};

TravelRadiusMap.defaultProps = {
  className: '',
  radius: 100,
  stateName: null,
  pinOrigin: null
};
