import { useFeatureValue } from '@growthbook/growthbook-react';

import { GB_FEATURES } from 'dpl/shared/constants/gb_features';
import { isMobileUA } from 'dpl/shared/utils';

const IS_MOBILE_UA = isMobileUA();

export default function useBreederSearchUpdatesExp() {
  const featureValue = useFeatureValue(
    GB_FEATURES.EXP_BREEDER_SEARCH_UPDATES,
    'control'
  );

  const isExperiment = featureValue === 'variant' && IS_MOBILE_UA;

  return {
    isExperiment
  };
}
