import React, { useEffect } from 'react';
import { useSelector } from 'react-redux';

import BreederProfileLittersAndPuppiesTabContainer from 'dpl/containers/BreederProfile/Show/BreederProfileLittersAndPuppiesTabContainer';
import BreederProfileOverviewTabContainer from 'dpl/containers/BreederProfile/Show/BreederProfileOverviewTabContainer';
import BreederProfileOwnerStoriesTabContainer from 'dpl/containers/BreederProfile/Show/BreederProfileOwnerStoriesTabContainer';
import BreederProfileUpdatesTabContainer from 'dpl/containers/BreederProfile/Show/BreederProfileUpdatesTabContainer';
import { trackPageView } from 'dpl/util/analytics';

import useBreederProfileTabs from '../../hooks/useBreederProfileTabs';
import { PAGE_CATEGORIES, TAB_KEYS } from '../../utils/constants';
import useBreederSearchUpdatesExp from '../experiment/useBreederSearchUpdatesExp';
import BreederProfilePuppiesTab from '../experiment/BreederProfilePuppiesTab';

export default function BreederProfileTabContent() {
  const { activeTabKey } = useBreederProfileTabs();
  const { isExperiment } = useBreederSearchUpdatesExp();
  const { analyticsPageName } = useSelector(({ server }) => server);

  const tabContent = {
    [TAB_KEYS.OVERVIEW]: BreederProfileOverviewTabContainer,
    [TAB_KEYS.DOGS]: isExperiment
      ? BreederProfilePuppiesTab
      : BreederProfileLittersAndPuppiesTabContainer,
    [TAB_KEYS.UPDATES]: BreederProfileUpdatesTabContainer,
    /* TODO: Uncomment when ready */
    // [TAB_KEYS.PROGRAM]: BreederProfileProgramTabContainer,
    [TAB_KEYS.STORIES]: BreederProfileOwnerStoriesTabContainer
  };

  const TabContent = tabContent[activeTabKey];

  useEffect(() => {
    trackPageView({
      name: analyticsPageName,
      category: PAGE_CATEGORIES[activeTabKey]
    });
  }, [activeTabKey]);

  return <TabContent />;
}
