import React from 'react';
import PropTypes from 'prop-types';

import SmartImage from 'dpl/components/SmartImage';

import certifiedBreederBadge from 'dpl/assets/certified_breeder_badge.svg';

export default function CertifiedBreederBadge({ width, height }) {
  return (
    <SmartImage
      className="drop-shadow"
      wrapperOnlyClassName="absolute right-0 bottom-0"
      width={width}
      height={height}
      src={certifiedBreederBadge}
    />
  );
}

CertifiedBreederBadge.propTypes = {
  width: PropTypes.string,
  height: PropTypes.string
};

CertifiedBreederBadge.defaultProps = {
  width: '16px',
  height: '16px'
};
