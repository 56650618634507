import React, { useRef, useState } from 'react';
import { useSelector } from 'react-redux';
import { isEmpty } from 'validate.js';
import PropTypes from 'prop-types';
import classnames from 'classnames';
import { Splide, SplideSlide } from '@splidejs/react-splide';

import Icon from 'dpl/common/components/Icon';
import SmartImage from 'dpl/components/SmartImage';
import useResourceQuery from 'dpl/shared/fetching/hooks/useResourceQuery';
import { aboutBreedsResourceDefinition } from 'dpl/shared/utils/tptConnectResourceDefinitions';
import Button from 'dpl/common/design_system/Button';
import {
  ANALYTICS_EVENTS,
  ANALYTICS_VIEW_FEATURE_LOCATIONS,
  ANALYTICS_VIEW_FEATURES,
  ANALYTICS_VIEWS
} from 'dpl/constants/analytics';
import { sendRudderstackEvent } from 'dpl/util/analytics';
import SectionViewTracker from 'dpl/common/components/SectionViewTracker';
import SkeletonBlock from 'dpl/common/design_system/SkeletonBlock';
import { makeSequence } from 'dpl/shared/utils';
import { addQueryToUrl } from 'dpl/util/queryString';

const SPLIDE_OPTIONS = {
  arrows: false,
  dragMinThreshold: 20,
  flickMaxPages: 1,
  flickPower: 300,
  gap: 16,
  keyboard: 'focused',
  pagination: false,
  perPage: 1,
  snap: true
};

function AboutBreedSlide({ breed, position }) {
  const buttonLabel = `Search ${breed.pluralized_name} near you`;

  function handleClick() {
    sendRudderstackEvent(ANALYTICS_EVENTS.BUTTON_CLICKED, {
      button_cta: buttonLabel,
      view: ANALYTICS_VIEWS.BREEDER_PROFILE,
      view_feature: ANALYTICS_VIEW_FEATURES.SEARCH_BREED_NEAR_YOU_BUTTON,
      view_feature_locations:
        ANALYTICS_VIEW_FEATURE_LOCATIONS.ABOUT_BREED_MODULE,
      position
    });

    const url = addQueryToUrl(
      {
        origin_view: ANALYTICS_VIEWS.BREEDER_PROFILE,
        origin_view_feature_location:
          ANALYTICS_VIEW_FEATURE_LOCATIONS.ABOUT_BREED_MODULE
      },
      breed.search_url
    );

    window.location.assign(url);
  }

  return (
    <SplideSlide key={breed.name}>
      <div className="flex flex-column flex-md-row items-center gap-x-64 gap-y-32">
        <div className="w-100">
          {breed.photos.length === 3 ? (
            <div className="AboutBreedModule__photos br3 overflow-hidden">
              {breed.photos.map((photo, idx) => (
                <SmartImage
                  key={idx}
                  className="h-100 w-100 object-cover"
                  loadingClass="bg-light-gray"
                  src={photo.photo_url}
                  alt={photo.alt_text}
                />
              ))}
            </div>
          ) : (
            <div className="aspect-ratio aspect-ratio--16x9 relative">
              <div className="aspect-ratio--object">
                <SmartImage
                  Tag="div"
                  className="w-100 h-100 cover bg-center"
                  wrapperOnlyClassName="br3 overflow-hidden"
                  loadingClass="bg-light-gray"
                  src={breed.photos[0].photo_url}
                  alt={breed.photos[0].alt_text}
                />
              </div>
            </div>
          )}
        </div>
        <div className="w-100">
          <h3 className="stone-dark font-24 md:font-40 lh-title">
            About the {breed.name}
          </h3>
          {!isEmpty(breed.traits) && (
            <div className="flex flex-column flex-md-row flex-wrap mt6 mt8-md mb6 mb12-md gap-8">
              {breed.traits.map((trait, idx) => (
                <div
                  key={idx}
                  className="bg-stone-700 white br3 pv6 ph4 flex flex-column justify-center items-center flex-basis-0 flex-grow-1 gap-8 tc"
                >
                  <p className="AboutBreedModule__traitDescription font-serif font-20">
                    {trait.description}
                  </p>
                  <p className="white o-70 font-14">{trait.category}</p>
                </div>
              ))}
            </div>
          )}
          <Button
            className="font-16 w-100 w-auto-m"
            variant="outline"
            size="default"
            onClick={handleClick}
          >
            {buttonLabel}
          </Button>
        </div>
      </div>
    </SplideSlide>
  );
}

AboutBreedSlide.propTypes = {
  breed: PropTypes.shape({
    name: PropTypes.string,
    pluralized_name: PropTypes.string,
    photos: PropTypes.arrayOf(
      PropTypes.shape({
        photo_url: PropTypes.string,
        alt_text: PropTypes.string
      })
    ),
    search_url: PropTypes.string,
    slug: PropTypes.string,
    traits: PropTypes.arrayOf(
      PropTypes.shape({
        category: PropTypes.string,
        description: PropTypes.string
      })
    )
  }).isRequired,
  position: PropTypes.number.isRequired
};

export default function AboutBreedModule() {
  const { analyticsPageName, breederProfileId } = useSelector(
    ({ server }) => server
  );
  const [activeIndex, setActiveIndex] = useState(0);
  const {
    isLoading: isLoadingBreeds,
    value: {
      data: { breeds }
    }
  } = useResourceQuery(aboutBreedsResourceDefinition(breederProfileId));

  const breedsCount = breeds.length;

  const splideRef = useRef(null);
  const isNextButtonDisabled = activeIndex >= breedsCount - 1;
  const isPrevButtonDisabled = activeIndex <= 0;

  function handleActiveSlide(slide) {
    setActiveIndex(slide.index);
  }

  return (
    <SectionViewTracker
      name={analyticsPageName}
      category="breeder-profile-about-breed"
    >
      <div
        className={classnames('AboutBreedModule pv10 pv16-md', {
          'bg-subtle-light-gray': isLoadingBreeds,
          'bg-sky': !isLoadingBreeds
        })}
      >
        <div className="container-xxl">
          {(() => {
            if (isLoadingBreeds) {
              return (
                <div className="flex flex-column flex-md-row items-center gap-x-64 gap-y-32">
                  <div className="w-100">
                    <SkeletonBlock className="br3" height="370px" />
                  </div>
                  <div className="w-100">
                    <SkeletonBlock
                      className="font-24 md:font-40 lh-title"
                      height="1em"
                      width="80%"
                    />
                    <div className="flex flex-column flex-md-row flex-wrap mt6 mt8-md mb6 mb12-md gap-8">
                      {makeSequence(3).map(index => (
                        <SkeletonBlock
                          key={index}
                          className="br3 font-20 flex-basis-0-l flex-grow-1"
                          height="7em"
                          width="100%"
                        />
                      ))}
                    </div>
                    <SkeletonBlock
                      className="font-16 w-100 w-auto-ns"
                      height="2em"
                      width="50%"
                    />
                  </div>
                </div>
              );
            }

            return (
              <>
                <Splide
                  options={{
                    ...SPLIDE_OPTIONS,
                    drag: breedsCount > 0,
                    start: activeIndex
                  }}
                  ref={splideRef}
                  onActive={handleActiveSlide}
                >
                  {breeds.map((breed, idx) => (
                    <AboutBreedSlide key={idx} breed={breed} position={idx} />
                  ))}
                </Splide>
                {breedsCount > 1 && (
                  <div className="mt6 secondary">
                    <button
                      type="button"
                      className={classnames(
                        'bg-white ba b--default br-100 mr4',
                        {
                          'o-60': isPrevButtonDisabled
                        }
                      )}
                      disabled={isPrevButtonDisabled}
                      onClick={() => splideRef.current.go('<')}
                    >
                      <div className="flex items-center justify-center ph3 pv3">
                        <Icon name="fetch-arrow-left" />
                      </div>
                    </button>
                    <button
                      type="button"
                      className={classnames('bg-white ba b--default br-100', {
                        'o-60': isNextButtonDisabled
                      })}
                      disabled={isNextButtonDisabled}
                      onClick={() => splideRef.current.go('>')}
                    >
                      <div className="flex items-center justify-center ph3 pv3">
                        <Icon name="fetch-arrow-right" />
                      </div>
                    </button>
                  </div>
                )}
              </>
            );
          })()}
        </div>
      </div>
    </SectionViewTracker>
  );
}
