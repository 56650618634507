import React from 'react';
import PropTypes from 'prop-types';
import { useSelector } from 'react-redux';

import useResourceQuery from 'dpl/shared/fetching/hooks/useResourceQuery';

import { nearbyStatesResourceDefinition } from './utils/queryDefinitions';
import BreederProfileLocationLinksGroup from './BreederProfileLocationLinksGroup';

export default function BreederProfileNearbyStates({
  breedName,
  breedId,
  isStatePage,
  limit
}) {
  const { locationTitle } = useSelector(({ server }) => server);

  const { value: statesData, isFetching } = useResourceQuery(
    nearbyStatesResourceDefinition(breedId, locationTitle, limit)
  );

  return (
    <BreederProfileLocationLinksGroup
      breedName={breedName}
      linksData={statesData}
      header={`Find ${breedName} puppies in nearby states`}
      wideLayout
      linkType="state"
      limit={limit}
      isStatePage={isStatePage}
      isFetching={isFetching}
    />
  );
}

BreederProfileNearbyStates.propTypes = {
  breedName: PropTypes.string.isRequired,
  breedId: PropTypes.string.isRequired,
  isStatePage: PropTypes.bool,
  limit: PropTypes.number
};

BreederProfileNearbyStates.defaultProps = {
  isStatePage: false,
  limit: 10
};
