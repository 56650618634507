import { useSelector } from 'react-redux';

import useResourceQuery from 'dpl/shared/fetching/hooks/useResourceQuery';
import { breedRelationsResourceDefinition } from 'dpl/shared/utils/tptConnectResourceDefinitions';

export default function useRelatedBreeds() {
  const { server, queryParams } = useSelector(state => state);
  const { breeds = [{}], citySlug = '', preappliedFilters } = server;

  const breedId = server.breedId || queryParams.breed_id || breeds[0]?.id;
  const stateSlug = preappliedFilters
    ? preappliedFilters.stateSlug
    : server.stateSlug;

  const {
    isFetching,
    value: { data }
  } = useResourceQuery({
    ...breedRelationsResourceDefinition(breedId, citySlug, stateSlug),
    auto: Boolean(breedId)
  });

  return { isFetching, data };
}
