import React from 'react';
import { useSelector } from 'react-redux';

import BreederProfilePastPuppiesSectionContainer from 'dpl/containers/BreederProfile/Show/BreederProfilePastPuppiesSectionContainer';

import PuppiesList from './PuppiesList';
import UpcomingLittersList from './UpcomingLittersList';

export default function BreederProfilePuppiesTab() {
  const { hasCurrentLitters, hasPastPuppies } = useSelector(
    ({ server }) => server
  );

  return (
    <div className="BreederProfilePuppiesTab">
      {hasCurrentLitters && (
        <>
          <PuppiesList />
          <UpcomingLittersList />
        </>
      )}
      {hasPastPuppies && <BreederProfilePastPuppiesSectionContainer />}
    </div>
  );
}
