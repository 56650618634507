import { useDispatch, useSelector } from 'react-redux';

import { pushParams } from 'dpl/actions/query_params';

import {
  TAB_KEYS,
  TAB_KEYS_EXPERIMENT,
  TAB_NAMES,
  TAB_NAMES_EXPERIMENT,
  TAB_VIEW_FEATURE_LOCATIONS
} from '../utils/constants';
import useBreederSearchUpdatesExp from '../Show/experiment/useBreederSearchUpdatesExp';

export default function useBreederProfileTabs() {
  const { isExperiment } = useBreederSearchUpdatesExp();

  const expTabKeys = isExperiment ? TAB_KEYS_EXPERIMENT : TAB_KEYS;
  const expTabNames = isExperiment ? TAB_NAMES_EXPERIMENT : TAB_NAMES;

  const dispatch = useDispatch();

  const {
    testimonialsCount,
    hasBreederPosts,
    hasPastPuppies,
    hasCurrentLitters
  } = useSelector(({ server }) => server);

  const enabledTabKeyValues = Object.values(expTabKeys).filter(tabKey => {
    switch (tabKey) {
      case TAB_KEYS.DOGS: {
        return hasCurrentLitters || hasPastPuppies;
      }
      case TAB_KEYS.UPDATES: {
        return hasBreederPosts;
      }
      case TAB_KEYS.STORIES: {
        return testimonialsCount > 0;
      }
      default: {
        return true;
      }
    }
  });

  const enabledTabNameValues = Object.values(
    enabledTabKeyValues.map(tabKey =>
      isExperiment && tabKey === TAB_KEYS.STORIES
        ? `Reviews (${testimonialsCount})`
        : expTabNames[tabKey]
    )
  );

  const defaultTab =
    isExperiment && (hasCurrentLitters || hasPastPuppies)
      ? TAB_KEYS.DOGS
      : TAB_KEYS.OVERVIEW;

  const activeTabKey = useSelector(({ queryParams: { tab } }) =>
    enabledTabKeyValues.includes(tab) ? tab : defaultTab
  );

  const activeTabIndex = enabledTabKeyValues.indexOf(activeTabKey);
  const viewFeatureLocation = TAB_VIEW_FEATURE_LOCATIONS[activeTabKey];

  function handleTabClick(tabIndex) {
    const tabKey = enabledTabKeyValues[tabIndex] || defaultTab;

    dispatch(pushParams({ tab: tabKey }, { hash: true }));
  }

  return {
    activeTabIndex,
    activeTabKey,
    tabNames: enabledTabNameValues,
    tabKeys: enabledTabKeyValues,
    onTabClick: handleTabClick,
    viewFeatureLocation
  };
}
