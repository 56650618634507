import classnames from 'classnames';
import PropTypes from 'prop-types';
import React, { useReducer, useState } from 'react';
import { useDispatch } from 'react-redux';

import ClickableDiv from 'dpl/common/components/ClickableDiv';
import Icon from 'dpl/common/components/Icon';
import SmartImage from 'dpl/components/SmartImage';
import { createBreederProfilePaginationAction } from 'dpl/containers/BreederProfile/Show/BreederProfilePhotoAlbumContainer';
import { isLessThanBreakpoint } from 'dpl/util/grid';

import ImagePlaceholder from './ImagePlaceholder';
import MediaGridCarousel from './MediaGridCarousel';
import MediaGridSkeleton from './MediaGridSkeleton';

const MAX_VISIBLE_THUMBNAIL_COUNT = 3;
const VIEW_ONE_MORE_LABEL = 'View one more photo';
const VIEW_ALL_LABEL = 'View all photos';

const CAROUSEL_TYPES = {
  PUPPY: 'puppy',
  // NOTE: The "program" carousel type is for breeder program photos only.
  // It shows the photos returned by the photographsResourceDefinition.
  PROGRAM: 'program'
};

function getViewMoreButtonLabel({ totalCount, visibleCount }) {
  if (totalCount === 1 || totalCount - visibleCount === 1) {
    return VIEW_ONE_MORE_LABEL;
  }

  if (totalCount > 1 && totalCount > visibleCount) {
    return VIEW_ALL_LABEL;
  }

  return null;
}

export default function MediaGrid({
  className,
  gallery,
  emptyPlaceholderText,
  isLoading,
  carouselType,
  style
}) {
  const dispatch = useDispatch();
  const [activeSlideIndex, setActiveSlideIndex] = useState(0);

  const [isCarouselOpen, toggleIsCarouselOpen] = useReducer(
    isOpen => !isOpen,
    false
  );

  const [profileThumbnail, ...galleryThumbnails] = gallery;

  const visibleThumbnails = galleryThumbnails.slice(
    0,
    MAX_VISIBLE_THUMBNAIL_COUNT
  );

  const totalThumbnailCount = galleryThumbnails.length;
  const visibleThumbnailCount = visibleThumbnails.length;

  const viewMoreButtonLabel = getViewMoreButtonLabel({
    totalCount: totalThumbnailCount,
    visibleCount: visibleThumbnailCount
  });

  function openMediaCarousel(slideIndex) {
    if (carouselType === CAROUSEL_TYPES.PROGRAM) {
      const photoId = gallery[slideIndex]?.id;
      dispatch(createBreederProfilePaginationAction(photoId));
    } else {
      toggleIsCarouselOpen();
      setActiveSlideIndex(slideIndex);
    }
  }

  function handleCarouselClose() {
    toggleIsCarouselOpen();
    setActiveSlideIndex(0);
  }

  function handleViewMoreClick() {
    if (galleryThumbnails.length === 1) {
      openMediaCarousel(1);
    } else if (viewMoreButtonLabel === VIEW_ALL_LABEL) {
      openMediaCarousel(0);
    } else {
      openMediaCarousel(MAX_VISIBLE_THUMBNAIL_COUNT + 1);
    }
  }

  if (isLoading) {
    return <MediaGridSkeleton className={className} />;
  }

  if (!profileThumbnail) {
    return (
      <div
        className={classnames('MediaGrid', className)}
        data-thumbnails-visible="false"
      >
        <ImagePlaceholder
          className="bg-branch-light br3"
          caption={emptyPlaceholderText}
          imgSize={isLessThanBreakpoint('md') ? '80px' : '100px'}
        />
      </div>
    );
  }

  return (
    <div
      className={classnames(
        'MediaGrid br3 overflow-hidden relative',
        className
      )}
      style={style}
      data-thumbnails-visible={totalThumbnailCount > 1}
    >
      <ClickableDiv
        className="MediaGrid__profile-thumbnail"
        onClick={() => openMediaCarousel(0)}
      >
        <SmartImage
          className="h-100 w-100 object-cover"
          loadingClass="bg-light-gray"
          crop
          src={
            profileThumbnail.type === 'Video'
              ? profileThumbnail.thumbnail_url
              : profileThumbnail.url
          }
        />
        {profileThumbnail.type === 'Video' && (
          <Icon
            name="fetch-play-video"
            width="28px"
            height="28px"
            className="absolute bottom-0 mv2 mh2"
          />
        )}
      </ClickableDiv>
      {galleryThumbnails.length > 1 && (
        <div className="MediaGrid__gallery">
          {visibleThumbnails.map((galleryThumbnail, index) => {
            const isVideo = galleryThumbnail.type === 'Video';
            return (
              <ClickableDiv
                key={galleryThumbnail.id}
                className="MediaGrid__gallery-thumbnail relative"
                onClick={() => openMediaCarousel(index + 1)}
              >
                <SmartImage
                  className="h-100 w-100 object-cover"
                  loadingClass="bg-light-gray"
                  crop
                  src={
                    isVideo
                      ? galleryThumbnail.thumbnail_url
                      : galleryThumbnail.url
                  }
                />
                {isVideo && (
                  <Icon
                    name="fetch-play-video"
                    width="28px"
                    height="28px"
                    className="absolute bottom-0 mv2 mh2"
                  />
                )}
              </ClickableDiv>
            );
          })}
        </div>
      )}
      {viewMoreButtonLabel && (
        <button
          type="button"
          className="MediaGrid__gallery__view-more bg-subtle-gray br2 f2 fw-medium stone-700 absolute bottom-0 right-0 mv2 mh2 pv2 ph4"
          onClick={handleViewMoreClick}
        >
          {viewMoreButtonLabel}
        </button>
      )}
      {isCarouselOpen && (
        <MediaGridCarousel
          mediaFiles={gallery}
          selectedMediaIdx={activeSlideIndex}
          onClose={handleCarouselClose}
          bulletsContainerClassName="d-none"
          mobileCompact={false}
        />
      )}
    </div>
  );
}

MediaGrid.propTypes = {
  className: PropTypes.string,
  // eslint-disable-next-line react/forbid-prop-types
  style: PropTypes.object,
  gallery: PropTypes.arrayOf(
    PropTypes.shape({
      type: PropTypes.oneOf(['Photograph', 'Video']),
      id: PropTypes.number.isRequired,
      caption: PropTypes.string,
      three_two_thumbnail_url: PropTypes.string.isRequired,
      url: PropTypes.string.isRequired
    })
  ).isRequired,
  emptyPlaceholderText: PropTypes.string,
  isLoading: PropTypes.bool,
  carouselType: PropTypes.oneOf(Object.values(CAROUSEL_TYPES))
};

MediaGrid.defaultProps = {
  className: null,
  style: null,
  emptyPlaceholderText: 'The breeder hasn’t uploaded photos yet',
  isLoading: false,
  carouselType: CAROUSEL_TYPES.PUPPY
};
