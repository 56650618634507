import { Splide, SplideSlide } from '@splidejs/react-splide';
import classnames from 'classnames';
import PropTypes from 'prop-types';
import React, { useRef, useState } from 'react';
import { isEmpty } from 'validate.js';
import { useSelector } from 'react-redux';

import Icon from 'dpl/common/components/Icon';
import { isLessThanBreakpoint } from 'dpl/util/grid';

import BreederProfileLitterPuppyCard, {
  PuppyPropTypes
} from '../BreederProfileLitterPuppyCard';
import useBreederSearchUpdatesExp from '../experiment/useBreederSearchUpdatesExp';

const IS_LT_MD = isLessThanBreakpoint('md');
const IS_LT_LG = isLessThanBreakpoint('lg');

function getNumberOfCardsPerPage() {
  if (IS_LT_MD) return 1;
  if (IS_LT_LG) return 2;

  return 3;
}

const CARDS_PER_PAGE = getNumberOfCardsPerPage();

export default function BreederProfilePastPuppiesCarousel({
  firstName,
  breederProfileId,
  puppies,
  className
}) {
  const { isExperiment } = useBreederSearchUpdatesExp();
  const { hasCurrentLitters } = useSelector(({ server }) => server);

  const splideRef = useRef(null);
  const [activeIndex, setActiveIndex] = useState(0);

  const puppyCount = puppies.length;
  const isNextButtonDisabled = activeIndex >= puppyCount - CARDS_PER_PAGE;
  const isPrevButtonDisabled = activeIndex <= 0;
  const peekable = IS_LT_MD && puppyCount > 1;

  if (isEmpty(puppies)) {
    return null;
  }

  return (
    <div
      className={classnames('BreederProfilePastPuppiesCarousel', className, {
        pv12: !isExperiment,
        pv6: isExperiment && hasCurrentLitters,
        'bg-subtle-light-gray': hasCurrentLitters || !isExperiment,
        'bg-white': isExperiment && !hasCurrentLitters
      })}
    >
      <div className="container-xxl">
        <div className="mb5 mb6-md">
          <h3
            className={classnames('lh-title', {
              'font-20 md:font-24 mb2': !isExperiment,
              'font-24': isExperiment
            })}
          >
            {isExperiment ? 'Past puppies' : 'Previous litters'}
          </h3>
          <p className="font-16 md:font-18 secondary">
            Puppies from {firstName}’s previous litters who are now in their new
            homes.
          </p>
        </div>
        <Splide
          ref={splideRef}
          className={IS_LT_MD ? 'nh6' : 'br3 overflow-hidden'}
          onActive={({ index }) => setActiveIndex(index)}
          options={{
            arrows: false,
            drag: puppyCount > CARDS_PER_PAGE,
            dragMinThreshold: 20,
            flickMaxPages: CARDS_PER_PAGE,
            flickPower: 300,
            gap: IS_LT_MD ? 8 : 16,
            keyboard: 'focused',
            pagination: false,
            perPage: CARDS_PER_PAGE,
            snap: true,
            start: activeIndex,
            ...(peekable && { padding: { right: 36, left: 24 } })
          }}
        >
          {puppies.map((puppy, puppyIndex) => (
            <SplideSlide key={puppy.id}>
              <BreederProfileLitterPuppyCard
                className={classnames({
                  // Offset extra right padding in last peekable slide
                  nr2: peekable && puppyIndex === puppyCount - 1
                })}
                puppy={puppy}
                breederProfileId={breederProfileId}
              />
            </SplideSlide>
          ))}
        </Splide>
        {CARDS_PER_PAGE > 1 && puppyCount > CARDS_PER_PAGE && (
          <div className="mt3 mt7-md secondary">
            <button
              type="button"
              className={classnames('bg-white ba b--default br-100 mr4', {
                'o-60': isPrevButtonDisabled
              })}
              disabled={isPrevButtonDisabled}
              onClick={() => splideRef.current.go('<')}
            >
              <div className="flex items-center justify-center ph3 pv3">
                <Icon name="fetch-arrow-left" />
              </div>
            </button>
            <button
              type="button"
              className={classnames('bg-white ba b--default br-100', {
                'o-60': isNextButtonDisabled
              })}
              disabled={isNextButtonDisabled}
              onClick={() => splideRef.current.go('>')}
            >
              <div className="flex items-center justify-center ph3 pv3">
                <Icon name="fetch-arrow-right" />
              </div>
            </button>
          </div>
        )}
      </div>
    </div>
  );
}

BreederProfilePastPuppiesCarousel.propTypes = {
  firstName: PropTypes.string.isRequired,
  breederProfileId: PropTypes.string.isRequired,
  puppies: PropTypes.arrayOf(PuppyPropTypes).isRequired,
  className: PropTypes.string
};

BreederProfilePastPuppiesCarousel.defaultProps = {
  className: null
};
