import React, { useReducer } from 'react';
import { useDispatch, useSelector } from 'react-redux';
import classnames from 'classnames';

import useResourceQuery from 'dpl/shared/fetching/hooks/useResourceQuery';
import { littersResourceDefinition } from 'dpl/shared/utils/tptConnectResourceDefinitions';
import { pushParams } from 'dpl/actions/query_params';
import { LITTER_ID } from 'dpl/constants/query_params';
import { LITTER_STATES } from 'dpl/shared/constants/shared';
import { LitterCard } from 'dpl/containers/BreederProfile/Show/BreederProfileLittersSectionContainer';

import BreederProfileLittersSkeleton from '../skeletons/BreederProfileLittersSkeleton';

const MAX_LITTERS_TO_SHOW = 3;

export default function UpcomingLittersList() {
  const dispatch = useDispatch();
  const { breederProfileId } = useSelector(({ server }) => server);

  const [isExpanded, toggleIsExpanded] = useReducer(
    expanded => !expanded,
    false
  );

  const {
    isFetching,
    value: { data: litters }
  } = useResourceQuery(
    littersResourceDefinition(breederProfileId, {
      exclude_adult: true
    })
  );

  const upcomingLitters = litters.filter(
    litter => litter.status !== LITTER_STATES.BORN
  );

  if (isFetching) {
    return <BreederProfileLittersSkeleton />;
  }

  if (upcomingLitters.length === 0) {
    return null;
  }

  const hasPuppies = litters.some(litter => litter.puppies.length > 0);
  const hasMoreLitters = litters.length > MAX_LITTERS_TO_SHOW;

  function onLitterClick(litterId) {
    dispatch(pushParams({ [LITTER_ID]: litterId }, { hash: true }));
  }

  return (
    <div
      className={classnames('UpcomingLittersList container-xxl', {
        pv6: hasPuppies,
        pb6: !hasPuppies
      })}
    >
      <h3 className="mb6 font-24 lh-title">Upcoming litters</h3>
      <div className="BreederProfileLittersList grid gap-8">
        {upcomingLitters.map(litter => (
          <div key={litter.id} className="flex ws-normal">
            <LitterCard litter={litter} onLitterClick={onLitterClick} />
          </div>
        ))}
      </div>

      {hasMoreLitters && (
        <button
          onClick={toggleIsExpanded}
          className="dn dib-ns underline stone-700 font-16 mt6"
          type="button"
        >
          {isExpanded ? 'Show less' : 'Show more'}
        </button>
      )}
    </div>
  );
}
