// WIP
// complete text and icon variants
// add badges and different state styles
// add `type` prop to determine input type (i.e. radio)
// update onPillClick to onChange handler
import React from 'react';
import PropTypes from 'prop-types';
import classnames from 'classnames';

import SmartImage from 'dpl/components/SmartImage';

import { PILLS_VARIANT } from './utils/constants';
import ClickableDiv from '../components/ClickableDiv';

const COMMON_CLASSNAMES = 'DSPill font-16 br-pill ba b--gray-300';

export function Pill({ label, onPillClick, photoUrl, selected, variant }) {
  if (variant === PILLS_VARIANT.PHOTO) {
    return (
      <ClickableDiv
        className={classnames(
          COMMON_CLASSNAMES,
          'flex items-center pv2 pl2 pr4 stone-700 bg-white',
          {
            'b--stone-700': selected
          }
        )}
        onClick={onPillClick}
      >
        <div className="f0 br-100 ba b--gray-300 overflow-hidden mr2">
          <SmartImage
            Tag="div"
            crop
            lazy
            className="zoom cover"
            height="28px"
            src={photoUrl}
            width="28px"
          />
        </div>
        <div>
          <p className="nowrap">{label}</p>
        </div>
      </ClickableDiv>
    );
  }

  return (
    <button
      className={classnames(COMMON_CLASSNAMES, 'pv1 ph4', {
        'bg-stone-700 white': selected,
        'stone-700 bg-white': !selected
      })}
      type="button"
      onClick={onPillClick}
    >
      {label}
    </button>
  );
}

Pill.propTypes = {
  onPillClick: PropTypes.func.isRequired,
  label: PropTypes.string.isRequired,
  photoUrl: PropTypes.string,
  selected: PropTypes.bool,
  variant: PropTypes.oneOf(Object.values(PILLS_VARIANT))
};

Pill.defaultProps = {
  photoUrl: null,
  selected: false,
  variant: PILLS_VARIANT.TEXT
};

export default function Pills({
  className,
  items,
  onPillClick,
  selectedPillIdx,
  variant
}) {
  return (
    <div className={classnames('DSPills flex gap-8', className)}>
      {items.map((item, idx) => (
        <Pill
          key={idx}
          label={item.label}
          onPillClick={() => onPillClick(idx)}
          photoUrl={item.photo_url}
          selected={idx === selectedPillIdx}
          variant={variant}
        />
      ))}
    </div>
  );
}

Pills.propTypes = {
  className: PropTypes.string,
  onPillClick: PropTypes.func.isRequired,
  items: PropTypes.arrayOf(
    PropTypes.shape({
      label: PropTypes.string,
      photo_url: PropTypes.string
    })
  ).isRequired,
  selectedPillIdx: PropTypes.number,
  variant: PropTypes.oneOf(Object.values(PILLS_VARIANT))
};

Pills.defaultProps = {
  className: null,
  selectedPillIdx: 0,
  variant: PILLS_VARIANT.TEXT
};
