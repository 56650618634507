import React from 'react';
import PropTypes from 'prop-types';
import classnames from 'classnames';
import { useSelector } from 'react-redux';
import { isEmpty } from 'validate.js';

import BreederProfileNearbyCities from 'dpl/components/BreederProfile/Index/BreederProfileList/BreederProfileNearbyCities';
import BreederProfileNearbyStates from 'dpl/components/BreederProfile/Index/BreederProfileList/BreederProfileNearbyStates';
import BreederProfileRelatedBreedsSkeleton from 'dpl/components/BreederProfile/Show/skeletons/BreederProfileRelatedBreedsSkeleton';
import RelatedBreedsModule from 'dpl/puppy_search_results/components/RelatedBreedsModule';
import useRelatedBreeds from 'dpl/puppy_search_results/hooks/useRelatedBreeds';

function NearbyLocationModule({ breed, breederProfileId, locationTitle }) {
  if (breed.exclude_from_city_pages) {
    return (
      <BreederProfileNearbyStates
        breedName={breed.name}
        stateName={locationTitle}
        breedId={breed.id}
        limit={10}
        wideLayout
      />
    );
  }

  return (
    <BreederProfileNearbyCities
      breedId={breed.id}
      breederProfileId={breederProfileId}
      breedName={breed.name}
      limit={10}
      wideLayout
    />
  );
}

NearbyLocationModule.propTypes = {
  breed: PropTypes.shape({
    id: PropTypes.number.isRequired,
    name: PropTypes.string.isRequired,
    exclude_from_city_pages: PropTypes.bool.isRequired
  }).isRequired,
  breederProfileId: PropTypes.string.isRequired,
  locationTitle: PropTypes.string.isRequired
};

export default function BreederProfileRelatedBreedsContainer() {
  const { breeds, breederProfileId, citySlug, locationTitle } = useSelector(
    ({ server }) => server
  );

  const { isFetching, data: relatedBreedsData } = useRelatedBreeds();

  return (
    <div className="BreederRelatedLinks pv12 pv20-md">
      <div className="container-xxl">
        {isFetching && <BreederProfileRelatedBreedsSkeleton />}
        {!isFetching && !isEmpty(relatedBreedsData) && (
          <div>
            <h2 className="font-24 md:font-40 lh-title mb6 mb8-md">
              Related breeds
            </h2>
            <RelatedBreedsModule className="mb6 mb20-lg" heading="" />
          </div>
        )}
        <div className={classnames({ dn: isFetching })}>
          {Boolean(citySlug || locationTitle) &&
            breeds.map(b => (
              <NearbyLocationModule
                key={b.id}
                breed={b}
                breederProfileId={breederProfileId}
                locationTitle={locationTitle}
              />
            ))}
        </div>
      </div>
    </div>
  );
}
