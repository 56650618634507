import React, { useEffect } from 'react';
import PropTypes from 'prop-types';
import { useDispatch, useSelector } from 'react-redux';

import { isMobileUA } from 'dpl/shared/utils';
import { addQueryToUrl } from 'dpl/util/queryString';
import { toggleMobileMenu } from 'dpl/actions/mobile_menu';
import { MENU_TYPES } from 'dpl/decorators/withMobileMenu';
import { ORDERED_SEARCH_TYPES } from 'dpl/shared/constants/shared';
import { HOME_PATH } from 'dpl/shared/constants/urls';
import {
  ANALYTICS_EVENTS,
  ANALYTICS_VIEW_FEATURE_LOCATIONS,
  ANALYTICS_VIEW_FEATURES,
  ANALYTICS_VIEWS
} from 'dpl/constants/analytics';
import Button from 'dpl/common/design_system/Button';
import { sendRudderstackEvent } from 'dpl/util/analytics';
import ClickableDiv from 'dpl/common/components/ClickableDiv';
import Icon from 'dpl/common/components/Icon';

const IS_MOBILE_UA = isMobileUA();

// components used for landing/SEO improvements on breeder profile page
function toggleStickyHeader(force) {
  const mainHeader = document.getElementById('mainHeader');
  mainHeader.classList.toggle('sticky', force);
  mainHeader.classList.toggle('top-0', force);
}

export function SearchPuppiesNearYouCard({ breeds }) {
  const dispatch = useDispatch();

  const { searchLocationTitle, searchUserCoordinates } = useSelector(
    ({ server }) => server
  );

  const isMobileMenuOpen = useSelector(({ mobileMenu }) =>
    Boolean(mobileMenu.openMobileMenuName)
  );

  const cardLabel =
    breeds.length > 1
      ? 'Search puppies near you'
      : `Search ${breeds[0].pluralized_name} near you`;

  function handleClick() {
    sendRudderstackEvent(ANALYTICS_EVENTS.BUTTON_CLICKED, {
      button_cta: 'Search breed near you',
      view: ANALYTICS_VIEWS.BREEDER_PROFILE,
      view_feature: ANALYTICS_VIEW_FEATURES.SEARCH_BREED_NEAR_YOU_CARD,
      view_feature_location:
        ANALYTICS_VIEW_FEATURE_LOCATIONS.PUPPY_CAROUSEL_SEARCH_CARD
    });

    if (breeds.length === 1) {
      const searchUrl = addQueryToUrl(
        {
          lat: searchUserCoordinates[0],
          long: searchUserCoordinates[1],
          locName: searchLocationTitle,
          origin_view: ANALYTICS_VIEWS.BREEDER_PROFILE,
          origin_view_feature_location:
            ANALYTICS_VIEW_FEATURE_LOCATIONS.PUPPY_CAROUSEL_SEARCH_CARD
        },
        `/${breeds[0].slug}/results`
      );

      window.location.assign(searchUrl);
    } else if (breeds.length > 1 && IS_MOBILE_UA) {
      toggleStickyHeader(true);
      dispatch(
        toggleMobileMenu(MENU_TYPES.SEARCH, ORDERED_SEARCH_TYPES.BREEDERS)
      );
    } else {
      window.location.assign(HOME_PATH);
    }
  }

  useEffect(() => {
    toggleStickyHeader(isMobileMenuOpen);
  }, [isMobileMenuOpen]);

  return (
    <>
      <ClickableDiv onClick={handleClick}>
        <div className="SearchPuppiesNearYouCard flex flex-column gap-24 justify-center items-center h-100 bg-white ba b--gray-200 br3">
          <div className="br-100 bg-gray-200 ph3 pv3">
            <Icon name="fetch-search" />
          </div>
          <p className="font-16 fw-medium stone-700">{cardLabel}</p>
        </div>
      </ClickableDiv>
    </>
  );
}

SearchPuppiesNearYouCard.propTypes = {
  breeds: PropTypes.arrayOf(
    PropTypes.shape({
      pluralized_name: PropTypes.string,
      slug: PropTypes.string
    })
  ).isRequired
};

export function NoAvailablePuppies({ breeds }) {
  const dispatch = useDispatch();

  const { searchLocationTitle, searchUserCoordinates } = useSelector(
    ({ server }) => server
  );

  const isMobileMenuOpen = useSelector(({ mobileMenu }) =>
    Boolean(mobileMenu.openMobileMenuName)
  );

  const buttonLabel =
    breeds.length > 1
      ? 'Search puppies near you'
      : `Search ${breeds[0].pluralized_name} near you`;

  function handleClick() {
    sendRudderstackEvent(ANALYTICS_EVENTS.BUTTON_CLICKED, {
      button_cta: 'Search breed near you',
      view: ANALYTICS_VIEWS.BREEDER_PROFILE,
      view_feature: ANALYTICS_VIEW_FEATURES.SEARCH_BREED_NEAR_YOU_BUTTON,
      view_feature_location:
        ANALYTICS_VIEW_FEATURE_LOCATIONS.NO_AVAILABLE_PUPPIES
    });

    if (breeds.length === 1) {
      const searchUrl = addQueryToUrl(
        {
          lat: searchUserCoordinates[0],
          long: searchUserCoordinates[1],
          locName: searchLocationTitle,
          origin_view: ANALYTICS_VIEWS.BREEDER_PROFILE,
          origin_view_feature_location:
            ANALYTICS_VIEW_FEATURE_LOCATIONS.NO_AVAILABLE_PUPPIES
        },
        `/${breeds[0].slug}/results`
      );

      window.location.assign(searchUrl);
    } else if (breeds.length > 1 && IS_MOBILE_UA) {
      toggleStickyHeader(true);
      dispatch(
        toggleMobileMenu(MENU_TYPES.SEARCH, ORDERED_SEARCH_TYPES.BREEDERS)
      );
    } else {
      window.location.assign(HOME_PATH);
    }
  }

  useEffect(() => {
    toggleStickyHeader(isMobileMenuOpen);
  }, [isMobileMenuOpen]);

  return (
    <>
      <h3 className="mb2 mb6-md font-24 md:font-40 lh-title">
        No available puppies
      </h3>
      <p className="font-18 secondary mb6 mb10-md">
        This breeder has no available puppies at the moment.
      </p>
      <Button
        className="font-16 w-100 w-auto-m"
        variant="outline"
        size="default"
        onClick={handleClick}
      >
        {buttonLabel}
      </Button>
    </>
  );
}

NoAvailablePuppies.propTypes = {
  breeds: PropTypes.arrayOf(
    PropTypes.shape({
      pluralized_name: PropTypes.string,
      slug: PropTypes.string
    })
  ).isRequired
};
