export const STATUS = {
  NONE: 'none',
  PREFERRED: 'preferred'
};

export const CARD_BADGE_WIDTH = '20px';
export const CARD_BADGE_HEIGHT = '22px';

export const EXPERIMENT_CARD_BADGE_WIDTH = '11px';
export const EXPERIMENT_CARD_BADGE_HEIGHT = '13px';
