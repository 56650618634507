import React from 'react';

import { isLessThanBreakpoint } from 'dpl/util/grid';
import SkeletonBlock from 'dpl/common/design_system/SkeletonBlock';
import { makeSequence } from 'dpl/shared/utils';

const IS_LT_MD = isLessThanBreakpoint('md');
const AVATAR_SIZE = IS_LT_MD ? '64px' : '72px';

function BreederPostCardSkeleton() {
  // Random count between 1 and 6
  const itemCount = Math.floor(Math.random() * 6 + 1);

  return (
    <div className="BreederPostCardSkeleteon br12 ba b--gray-300">
      <div className="relative pv4 ph4">
        <div className="flex items-center">
          <SkeletonBlock className="br-100" height="48px" width="48px" />
          <div className="ml2">
            <SkeletonBlock className="font-16 mb2" height="1em" width="6em" />
            <SkeletonBlock className="font-14" height="1em" width="18em" />
          </div>
        </div>
      </div>
      <div
        className={`BreederPostCardMediaGridSkeleton BreederPostCardMediaGrid relative ${itemCount === 5 ? 'aspect-ratio--5x6' : 'aspect-ratio--1x1'}`}
      >
        <div className="aspect-ratio--object">
          <div
            className={`BreederPostCardMediaGrid__grid grid gap-4 h-100 BreederPostCardMediaGrid__grid--${itemCount}`}
          >
            {makeSequence(itemCount).map(i => (
              <SkeletonBlock
                key={i}
                className="BreederPostCardMediaGrid__grid-item"
              />
            ))}
          </div>
        </div>
      </div>
      <div className="flex flex-column gap-8 md:gap-16 flex-md-row justify-between font-16 pb4 ph4 pt3">
        <div className="flex items-center" style={{ height: '2.5em' }}>
          <SkeletonBlock className="br-100" height="24px" width="24px" />
        </div>
        <SkeletonBlock
          className="br-3 ph3 pv3"
          height="2.5em"
          width={IS_LT_MD ? '100%' : '16em'}
        />
      </div>
    </div>
  );
}

export default function BreederProfileUpdatesTabSkeleton() {
  return (
    <div className="BreederProfileUpdatesTabSkeleton">
      <div className="flex items-center">
        <SkeletonBlock
          className="br-100"
          height={AVATAR_SIZE}
          width={AVATAR_SIZE}
        />
        <div className="ml4">
          <SkeletonBlock
            className="font-20 md:font-24 lh-title"
            height="1em"
            width="6em"
          />
          <SkeletonBlock
            className="font-14 br-pill mt2"
            height="2em"
            width="9em"
          />
        </div>
      </div>
      <div className="flex flex-column gap-16 md:gap-24 mv8 mv12-md w-100">
        <SkeletonBlock
          className="font-16 md:font-18"
          height="1em"
          width="6em"
        />
        {makeSequence(3).map(index => (
          <BreederPostCardSkeleton key={index} />
        ))}
      </div>
    </div>
  );
}
